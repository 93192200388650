export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103')
];

export const server_loads = [2,3,17,5,6,8,9,12,13];

export const dictionary = {
		"/(institution)/(app)": [18,[2,3],[,4]],
		"/(institution)/auth/callback": [102,[2,17]],
		"/(institution)/auth/login": [103,[2,17]],
		"/(institution)/(app)/error": [19,[2,3],[,4]],
		"/(institution)/(app)/private/council": [~20,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/commissions": [~21,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/communications": [22,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/communications/new": [23,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/day-order": [~24,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/day-order/new": [26,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/day-order/[dayOrderId]": [~25,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/meetings": [~27,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/meetings/current/[meetingId]": [31,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/meetings/new": [~32,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/meetings/[meetingId]": [~28,[2,3,5],[,4]],
		"/(institution)/(app)/private/council/meetings/[meetingId]/settings/dayorder": [29,[2,3,5,6],[,4]],
		"/(institution)/(app)/private/council/meetings/[meetingId]/settings/details": [30,[2,3,5,6],[,4]],
		"/(institution)/(app)/private/council/village-delegates": [~33,[2,3,5],[,4]],
		"/(institution)/(app)/private/dashboard": [34,[2,3,5],[,4]],
		"/(institution)/(app)/private/dispositions": [35,[2,3,5,7],[,4]],
		"/(institution)/(app)/private/dispositions/finalized": [~36,[2,3,5,7],[,4]],
		"/(institution)/(app)/private/dispositions/finalized/[dispositionId]": [~37,[2,3,5,7],[,4]],
		"/(institution)/(app)/private/dispositions/finalized/[dispositionId]/settings/documents": [38,[2,3,5,7,8],[,4]],
		"/(institution)/(app)/private/dispositions/finalized/[dispositionId]/settings/events": [39,[2,3,5,7,8],[,4]],
		"/(institution)/(app)/private/dispositions/finalized/[dispositionId]/settings/signature": [40,[2,3,5,7,8],[,4]],
		"/(institution)/(app)/private/dispositions/finalized/[dispositionId]/settings/steps": [41,[2,3,5,7,8],[,4]],
		"/(institution)/(app)/private/dispositions/proposals": [~42,[2,3,5,7],[,4]],
		"/(institution)/(app)/private/dispositions/proposals/new": [50,[2,3,5,7],[,4]],
		"/(institution)/(app)/private/dispositions/proposals/[dispositionId]": [~43,[2,3,5,7],[,4]],
		"/(institution)/(app)/private/dispositions/proposals/[dispositionId]/settings/cfpp": [44,[2,3,5,7,9],[,4]],
		"/(institution)/(app)/private/dispositions/proposals/[dispositionId]/settings/details": [45,[2,3,5,7,9],[,4]],
		"/(institution)/(app)/private/dispositions/proposals/[dispositionId]/settings/documents": [46,[2,3,5,7,9],[,4]],
		"/(institution)/(app)/private/dispositions/proposals/[dispositionId]/settings/signature": [47,[2,3,5,7,9],[,4]],
		"/(institution)/(app)/private/dispositions/proposals/[dispositionId]/settings/status": [48,[2,3,5,7,9],[,4]],
		"/(institution)/(app)/private/dispositions/proposals/[dispositionId]/settings/steps": [49,[2,3,5,7,9],[,4]],
		"/(institution)/(app)/private/documents/[typeSlug]": [~51,[2,3,5,10],[,4]],
		"/(institution)/(app)/private/documents/[typeSlug]/[categorySlug]": [~52,[2,3,5,10],[,4]],
		"/(institution)/(app)/private/documents/[typeSlug]/[categorySlug]/new": [54,[2,3,5,10],[,4]],
		"/(institution)/(app)/private/documents/[typeSlug]/[categorySlug]/[documentSlug]": [~53,[2,3,5,10],[,4]],
		"/(institution)/(app)/private/mayor": [~55,[2,3,5],[,4]],
		"/(institution)/(app)/private/profile": [56,[2,3,5],[,4]],
		"/(institution)/(app)/private/projects": [57,[2,3,5,11],[,4]],
		"/(institution)/(app)/private/projects/finalized": [~58,[2,3,5,11],[,4]],
		"/(institution)/(app)/private/projects/finalized/[projectId]": [~59,[2,3,5,11],[,4]],
		"/(institution)/(app)/private/projects/finalized/[projectId]/settings/documents": [60,[2,3,5,11,12],[,4]],
		"/(institution)/(app)/private/projects/finalized/[projectId]/settings/events": [61,[2,3,5,11,12],[,4]],
		"/(institution)/(app)/private/projects/finalized/[projectId]/settings/signature": [62,[2,3,5,11,12],[,4]],
		"/(institution)/(app)/private/projects/finalized/[projectId]/settings/steps": [63,[2,3,5,11,12],[,4]],
		"/(institution)/(app)/private/projects/proposals": [~64,[2,3,5,11],[,4]],
		"/(institution)/(app)/private/projects/proposals/new": [~73,[2,3,5,11],[,4]],
		"/(institution)/(app)/private/projects/proposals/[projectId]": [~65,[2,3,5,11],[,4]],
		"/(institution)/(app)/private/projects/proposals/[projectId]/settings/cfpp": [66,[2,3,5,11,13],[,4]],
		"/(institution)/(app)/private/projects/proposals/[projectId]/settings/details": [67,[2,3,5,11,13],[,4]],
		"/(institution)/(app)/private/projects/proposals/[projectId]/settings/documents": [68,[2,3,5,11,13],[,4]],
		"/(institution)/(app)/private/projects/proposals/[projectId]/settings/meet": [69,[2,3,5,11,13],[,4]],
		"/(institution)/(app)/private/projects/proposals/[projectId]/settings/signature": [70,[2,3,5,11,13],[,4]],
		"/(institution)/(app)/private/projects/proposals/[projectId]/settings/status": [71,[2,3,5,11,13],[,4]],
		"/(institution)/(app)/private/projects/proposals/[projectId]/settings/steps": [~72,[2,3,5,11,13],[,4]],
		"/(institution)/(app)/private/secretary": [~74,[2,3,5],[,4]],
		"/(institution)/(app)/private/settings/departments": [75,[2,3,5],[,4]],
		"/(institution)/(app)/private/settings/institution": [76,[2,3,5],[,4]],
		"/(institution)/(app)/private/settings/mandates": [~77,[2,3,5],[,4]],
		"/(institution)/(app)/private/settings/mandates/new": [79,[2,3,5],[,4]],
		"/(institution)/(app)/private/settings/mandates/[mandatId]": [78,[2,3,5],[,4]],
		"/(institution)/(app)/private/settings/partids": [80,[2,3,5],[,4]],
		"/(institution)/(app)/private/settings/users": [81,[2,3,5],[,4]],
		"/(institution)/(app)/private/settings/users/[userId]": [~82,[2,3,5],[,4]],
		"/(institution)/(app)/private/townhall/profile": [~83,[2,3,5],[,4]],
		"/(institution)/(app)/private/townhall/structure": [84,[2,3,5],[,4]],
		"/(institution)/(app)/private/vice-mayor/[userId]": [~85,[2,3,5],[,4]],
		"/(institution)/(app)/private/vote/[voteId]": [86,[2,3,5],[,4]],
		"/(institution)/(app)/private/vote/[voteId]/admin": [87,[2,3,5],[,4]],
		"/(institution)/(app)/public/dispositions": [88,[2,3,14],[,4]],
		"/(institution)/(app)/public/dispositions/finalized": [89,[2,3,14],[,4]],
		"/(institution)/(app)/public/dispositions/finalized/[dispositionId]": [90,[2,3,14],[,4]],
		"/(institution)/(app)/public/dispositions/proposals": [91,[2,3,14],[,4]],
		"/(institution)/(app)/public/dispositions/proposals/[dispositionId]": [92,[2,3,14],[,4]],
		"/(institution)/(app)/public/documents/[typeSlug]": [~93,[2,3,15],[,4]],
		"/(institution)/(app)/public/documents/[typeSlug]/[categorySlug]": [~94,[2,3,15],[,4]],
		"/(institution)/(app)/public/documents/[typeSlug]/[categorySlug]/[documentSlug]": [~95,[2,3,15],[,4]],
		"/(institution)/(app)/public/projects": [96,[2,3,16],[,4]],
		"/(institution)/(app)/public/projects/finalized": [97,[2,3,16],[,4]],
		"/(institution)/(app)/public/projects/finalized/[projectId]": [98,[2,3,16],[,4]],
		"/(institution)/(app)/public/projects/proposals": [99,[2,3,16],[,4]],
		"/(institution)/(app)/public/projects/proposals/[projectId]": [~100,[2,3,16],[,4]],
		"/(institution)/(app)/public/townhall/profile": [101,[2,3],[,4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';